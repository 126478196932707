import Popup from '@/components/popup.vue';
import { ITable } from '@/components/table';
import Table from '@/components/table/table.vue';
import store from '@/store';
import { IStateDevice } from '@/store/modules/devices';
import { IDevicesTranslations } from '@/translations';
import sort from '@/utils/sort';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';

import { deviceActionPopup, table } from '.';

@Component({
  name: 'Devices',
  components: { Table, Popup }
})
class Devices extends Vue {
  @Action('fetchDevices') public fetchDevices!: () => void;

  @Mutation('selectDevice') public selectDevice!: (device: IStateDevice) => void;

  @Getter('getDevices') public devices!: IStateDevice[];

  @Getter('getDevicesTranslations') public translations!: IDevicesTranslations;
  public table = {} as ITable<IStateDevice>; public dataLoaded = false; public deviceActionPopup = deviceActionPopup;
  public created() {

    this.fetchDevices();

    this.table = table(this.translations);
    this.table.onSelectedItem = this.onDeviceSelected;

    store.subscribe((mutation: MutationPayload) => {

      if (mutation.type === 'setLanguage') {

        this.table = { ...this.table, ...table(this.translations) };

      }

      if (mutation.type !== 'selectDevice') {

        const key = Object.keys(this.table.order) as Array<Extract<IStateDevice, string>>;
        const field = key[0];
        const order = this.table.order[key[0]];

        if (mutation.type !== 'setDevicesLoading') {
          this.table = {
            ...this.table,
            data: sort(field, order, this.devices)
          };
        }

      }

      if (mutation.type === 'newDevice') {
        this.triggerPopup({
          message: this.translations.create_success,
          type: 'success'
        });
      }
      if (mutation.type === 'setDevicesEvent') {
        this.triggerPopup({
          message: this.translations.assign_success,
          type: 'success'
        });
      }
      if (mutation.type === 'editDevice') {
        this.triggerPopup({
          message: this.translations.edit_success,
          type: 'success'
        });
      }
      if (mutation.type === 'setDevicesLocation') {
        this.triggerPopup({
          message: this.translations.location_success,
          type: 'success'
        });
      }
      if (mutation.type === 'setDevicesModel') {
        this.triggerPopup({
          message: this.translations.model_success,
          type: 'success'
        });
      }
      if (mutation.type === 'setDevicesStatus') {
        this.triggerPopup({
          message: this.translations.status_success,
          type: 'success'
        });
      }
      if (mutation.type === 'removeDevice') {
        this.triggerPopup({
          message: this.translations.delete_success,
          type: 'success'
        });
      }
      if (mutation.type === 'removeDevices') {
        this.triggerPopup({
          message: this.translations.deletes_success,
          type: 'success'
        });
      }

    });
  }
  public onDeviceSelected(device: IStateDevice) {
    this.selectDevice(device);
  }
  public triggerPopup({ message, type }: { message: string, type: string }) {
    this.deviceActionPopup = {
      ...this.deviceActionPopup,
      isActive: true,
      message,
      type,
    };
  }
}

export default Devices;
